const startseite = () => {
  window.addEventListener('load', () => {
    const about = document.getElementsByClassName('about')
    const backdrop = document.getElementsByClassName('landing__backdrop')
    const menu = document.getElementsByClassName('menu-wrapper')
    const infoBar = document.getElementsByClassName('info-bar')
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    if((about && backdrop && menu && infoBar) && (vw > 450)) {
      const height = about[0].getBoundingClientRect().height + menu[0].getBoundingClientRect().height
      backdrop[0].style.height = height +'px'
      backdrop[0].style.top = infoBar[0].getBoundingClientRect().height
    }
  })
}

export default startseite;
startseite();

